import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import Iframe from 'react-iframe'

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Comment changer de banque en 4 étapes avec BankBank</title>
                <meta name="description" content="
              Ça y'est c'est décide, je change de banque ! OK, mais comment faire exactement ? BankBank vous explique tout sur la mobilité bancaire." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Changer de banque – comment faire ?</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Changer de banque – comment faire ?</Title>
			
			<StaticImage
		  	src='../../images/changer-de-banque.jpeg'
			width={1200}
			alt='Changer de banque'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		<p>Le changement de banque, ou mobilité bancaire, implique d’opérer la domiciliation bancaire au sein d’un nouvel établissement c’est-à-dire au transfert des virements et prélèvements réguliers d’un compte à un autre. Procédure autrefois compliquée et coûteuse, les pouvoirs publics ont mis en place des mesures visant à faciliter la mobilité bancaire en faveur des consommateurs mais aussi pour dynamiser la concurrence.
		</p>	
<h2>Les mesures d’aide à la mobilité bancaire</h2>
<p>Depuis 2004, les pouvoirs publics ont instauré quelques obligations pour faciliter le changement de banque et pour éviter que les coûts ne représentent un frein pour le consommateur. Parmi ces mesures :
</p>
<ul>
	<li>La clôture gratuite des comptes de dépôt et sur livrets</li>
	<li>Mise à disposition gratuite d’un guide de la mobilité bancaire.</li>
	<li>La délivrance gratuite du récapitulatif des opérations courantes sur le compte courant</li>
	<li>Information du client de la présentation d’un chèque dans les 13 mois qui suivent la clôture du compte (afin d’éviter l’incident de paiement).</li>
	<li>Proposition de la part de la nouvelle banque d’effectuer la procédure de changement de domiciliation bancaire en transmettant les nouvelles coordonnées bancaires aux créanciers et ce, gratuitement.</li>
</ul>


<h2>Renforcement de la mesure avec la loi Macron</h2>
<p>Depuis le 6 février 2017, la loi Macron pour la croissance prévoit que la nouvelle banque se charge de toutes les formalités liées au changement de compte sans que cela n’engendre de frais pour le particulier. Le client reste libre d’accepter. Ce service comprend :
</p>
<ul>
	<li>La demande auprès de l’ancien établissement du récapitulatif des opérations courantes sur le compte courant</li>
	<li>Le transfert des opérations régulières en effectuant le changement de coordonnées bancaires auprès de tous les émetteurs</li>
	<li>La récupération des informations concernant les chèques non débités sur les chéquiers utilisés au cours des treize derniers mois.</li>

</ul>
<p>
Il convient de préciser que ces mesures s’appliquent uniquement sur les comptes bancaires. Si le client dispose de produits d’épargne la démarche ne sera pas aussi facilitée. Certains produits nécessitent une clôture avant de pouvoir opérer une ouverture tandis que d’autres engendrent des frais de transfert coûteux.
</p>
<h2>Quelles sont les étapes de la mobilité bancaire ?</h2>
<p>Pour commencer, après avoir comparé les banques le particulier devra ouvrir un nouveau compte bancaire dans l’établissement de son choix.
</p><p>
Ensuite, viendra la signature du mandat de mobilité bancaire auprès du nouvel établissement afin que celui-ci puisse procéder au changement de domiciliation bancaire.
</p><p>
Pour cela, la nouvelle banque demandera à l’ancienne banque le récapitulatif des opérations courantes, que cette dernière fournira dans les jours qui suivent et réalise le changement de domiciliation auprès des différents émetteurs de virements et de prélèvements récurrents.
</p><p>
Le client est informé par les émetteurs de la prise en compte de son changement de coordonnées bancaires.
</p><p>
Enfin, après vérification qu’aucun virement ou prélèvement ne sera effectué sur l’ancien compte, la clôture de compte pourra être pilotée par la nouvelle banque.</p>
			</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
